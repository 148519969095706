import type { VirtualMachine } from "@warrenio/api-spec/spec.oats.gen";
import {
    currentDateTime,
    randomElement,
    randomMac,
    randomPastDateTime,
    randomPrivateIpv4,
    randomTitle,
    randomUuid,
} from "../randomTypes.ts";
import { defaultBillingAccountId, notAvailableBillingAccountId } from "./billingAccountIds.ts";
import { mockUserId } from "./userExample.ts";
import { jhvVmId, ubuntuId, windowsId } from "./vmIds.ts";

const defaultFields = {
    user_id: mockUserId,
    billing_account: defaultBillingAccountId,
} satisfies Partial<VirtualMachine>;

const fastPoolFields = {
    current_pool_name: "Fast disk",
    current_pool_uuid: "d2fc6881-9316-46d7-b515-b6cddaeee927",
    designated_pool_name: "Fast disk",
    designated_pool_uuid: "d2fc6881-9316-46d7-b515-b6cddaeee927",
} satisfies Partial<VirtualMachine>;

export const vmList1: VirtualMachine[] = [
    {
        ...fastPoolFields,
        ...defaultFields,
        backup: false,
        created_at: "2024-01-16 09:07:33",
        description: "windows",
        hostname: "windows",
        mac: "52:54:00:4b:9d:96",
        memory: 4096,
        name: "Windows",
        os_name: "windows",
        license_type: "WINDOWS",
        os_version: "2022",
        private_ipv4: "10.3.119.161",
        public_ipv6: "2a05:1cc0:10:3::6:fffe",
        status: "running",
        storage: [
            {
                created_at: "2024-01-16 09:07:36",
                name: "vda",
                pool: "n/a",
                primary: true,
                replica: [],
                shared: false,
                size: 60,
                type: "n/a",
                user_id: 29,
                uuid: "1c7a59ec-91d3-4786-a11e-c1d60fca33a7",
            },
        ],
        updated_at: "2024-01-16 09:09:37",
        username: "administrator",
        uuid: windowsId,
        vcpu: 2,
    },
    {
        ...fastPoolFields,
        ...defaultFields,
        backup: true,
        created_at: "2023-10-02 12:03:04",
        description: "test",
        hostname: "test",
        mac: "52:54:00:8d:5a:44",
        memory: 1024,
        name: "testing VM",
        os_name: "ubuntu",
        os_version: "22.04.01",
        private_ipv4: "10.3.119.132",
        public_ipv6: "2a05:1cc0:10:3::6:fff9",
        status: "running",
        storage: [
            {
                created_at: "2023-10-02 12:03:07",
                name: "vda",
                pool: "n/a",
                primary: true,
                replica: [
                    {
                        created_at: "2023-12-13 02:00:15",
                        master_uuid: "dd4589ef-f075-4d92-913a-91e89ebdf552",
                        pool: "",
                        size: 30,
                        type: "backup",
                        uuid: "005944cd-f959-4b50-9af2-e57550b42bc3",
                    },
                    {
                        created_at: "2023-12-06 02:00:16",
                        master_uuid: "dd4589ef-f075-4d92-913a-91e89ebdf552",
                        pool: "",
                        size: 30,
                        type: "backup",
                        uuid: "f6678959-5fca-4188-8112-31f55421c66d",
                    },
                    {
                        created_at: "2023-11-29 02:00:16",
                        master_uuid: "dd4589ef-f075-4d92-913a-91e89ebdf552",
                        pool: "",
                        size: 30,
                        type: "backup",
                        uuid: "a8507748-4389-4e95-82d9-33e744ab899a",
                    },
                    {
                        created_at: "2023-11-22 02:00:13",
                        master_uuid: "dd4589ef-f075-4d92-913a-91e89ebdf552",
                        pool: "",
                        size: 30,
                        type: "backup",
                        uuid: "d21667d8-832a-47c3-a1b0-f51b0bea46af",
                    },
                    {
                        created_at: "2023-10-03 07:42:52",
                        master_uuid: "dd4589ef-f075-4d92-913a-91e89ebdf552",
                        pool: "",
                        size: 30,
                        type: "snapshot",
                        uuid: "2f6c5042-78c5-4d55-84b9-fc1ad4180133",
                    },
                ],
                shared: false,
                size: 30,
                type: "n/a",
                user_id: 29,
                uuid: "dd4589ef-f075-4d92-913a-91e89ebdf552",
            },
            {
                created_at: "2023-12-15 13:40:04",
                name: "vdb",
                pool: "n/a",
                primary: false,
                replica: [],
                shared: false,
                size: 30,
                type: "n/a",
                user_id: 29,
                uuid: "7b4d9c82-4c2b-47a6-931e-beaed9e152b2",
            },
        ],
        updated_at: "2024-01-24 02:00:11",
        username: "kadri",
        uuid: ubuntuId,
        vcpu: 1,
    },
];

export function generateVm(): VirtualMachine {
    const base = vmList1[0];
    const os_name = randomElement(["ubuntu", "windows", "centos", "wordpress"]);
    const license_type = os_name === "windows" ? "WINDOWS" : undefined;
    return {
        ...base,
        created_at: randomPastDateTime(),
        updated_at: currentDateTime(),
        uuid: randomUuid(),
        name: randomTitle(),
        private_ipv4: randomPrivateIpv4(),
        mac: randomMac(),
        os_name,
        license_type,
        status: randomElement(["creating", "running", "stopping", "stopped", "deleted"]),
        storage: base.storage.map((s) => ({ ...s, uuid: randomUuid() })),
    };
}

export const vmList2: VirtualMachine[] = [
    {
        ...defaultFields,
        backup: false,
        billing_account: notAvailableBillingAccountId,
        created_at: "2023-08-16 08:05:47",
        current_pool_name: "Standard",
        current_pool_uuid: "00000000-0000-0000-0000-00020e92ea50",
        description: "Ubuntu",
        designated_pool_name: "Standard",
        designated_pool_uuid: "00000000-0000-0000-0000-00020e92ea50",
        hostname: "Ubuntu",
        mac: "52:54:00:54:84:4a",
        memory: 1024,
        name: "Ubuntu, mille nimi on üllatavalt",
        os_name: "ubuntu",
        os_version: "22.04.01",
        private_ipv4: "10.1.29.118",
        status: "running",
        storage: [
            {
                created_at: "2023-08-16 08:05:50",
                name: "vda",
                pool: "n/a",
                primary: true,
                replica: [
                    {
                        created_at: "2023-10-06 08:15:30",
                        master_uuid: "56c03bfb-2384-49f1-a513-4fa166fb7c4f",
                        pool: "",
                        size: 20,
                        type: "snapshot",
                        uuid: "79b1a21f-94a3-42b1-9d99-36f3e1e82ff5",
                    },
                ],
                shared: false,
                size: 20,
                type: "n/a",
                user_id: 29,
                uuid: "56c03bfb-2384-49f1-a513-4fa166fb7c4f",
            },
            {
                created_at: "2023-12-11 08:28:31",
                name: "vdb",
                pool: "n/a",
                primary: false,
                replica: [],
                shared: false,
                size: 30,
                type: "n/a",
                updated_at: "2023-12-11 08:29:49",
                user_id: 29,
                uuid: "c2d2d895-748f-461e-8d84-89a14e5be368",
            },
        ],
        updated_at: "2024-02-05 13:28:48",
        username: "kadri",
        uuid: jhvVmId,
        vcpu: 2,
    },
];
