import type { IpAddress } from "@warrenio/api-spec/spec.oats.gen";
import { executeWithProbability } from "../executeWithProbability.ts";
import {
    currentDateTime,
    randomElement,
    randomPastDateTime,
    randomPrivateIpv4,
    randomTitle,
    randomUuid,
} from "../randomTypes.ts";
import { defaultBillingAccountId } from "./billingAccountIds.ts";
import { lb1Id } from "./loadBalancersIds.ts";
import { jhvVmId, ubuntuId } from "./vmIds.ts";

export const ipList1: IpAddress[] = [
    {
        id: 19286,
        address: "176.112.157.201",
        user_id: 328,
        billing_account_id: defaultBillingAccountId,
        type: "public",
        enabled: true,
        created_at: "2024-03-12 15:34:31",
        updated_at: "2024-03-12 15:34:32",
        uuid: "0085b7d4-dc91-4ca9-afce-852099fd0ea2",
        is_deleted: false,
        is_ipv6: false,
        name: "Ip Address 1 (Unassigned)",
    },
    {
        id: 19329,
        address: "176.112.157.207",
        user_id: 328,
        billing_account_id: defaultBillingAccountId,
        type: "public",
        enabled: true,
        created_at: "2024-03-14 10:58:02",
        updated_at: "2024-03-14 10:58:02",
        uuid: "5826828c-db97-450a-a71b-47289ee973db",
        is_deleted: false,
        is_ipv6: false,
        name: "Ip Address 2 (assigned 2 VM)",
        assigned_to: ubuntuId,
        assigned_to_resource_type: "virtual_machine",
        assigned_to_private_ip: "10.46.191.135",
    },
    // Without name, assigned to LB
    {
        id: 19440,
        address: "176.112.157.208",
        user_id: 328,
        billing_account_id: defaultBillingAccountId,
        type: "public",
        enabled: true,
        created_at: "2024-03-21 15:49:46",
        updated_at: "2024-03-21 15:49:46",
        uuid: "e6f2c66b-7161-4e7a-95a4-0157a85d5c86",
        is_deleted: false,
        is_ipv6: false,
        name: "",
        assigned_to: lb1Id,
        assigned_to_resource_type: "load_balancer",
        assigned_to_private_ip: "10.3.119.250",
    },
    // Without name, Unassigned
    {
        id: 19446,
        address: "176.112.157.209",
        user_id: 328,
        billing_account_id: defaultBillingAccountId,
        type: "public",
        enabled: true,
        created_at: "2024-03-21 15:49:46",
        updated_at: "2024-03-21 15:49:46",
        uuid: "7eb7eee5-d797-4086-b6bb-4ad240a1e0c3",
        is_deleted: false,
        is_ipv6: false,
        name: "",
    },
];

// TODO: IPv6?

export const ipList2: IpAddress[] = [
    {
        id: 18842,
        address: "176.112.148.203",
        user_id: 328,
        billing_account_id: defaultBillingAccountId,
        type: "public",
        enabled: true,
        created_at: "2024-03-15 11:53:38",
        updated_at: "2024-03-15 11:53:39",
        uuid: "615eb411-fe9c-41e9-ade7-df784e1bc750",
        is_deleted: false,
        is_ipv6: false,
        name: "IP in Jõhvi",
        assigned_to: jhvVmId,
        assigned_to_resource_type: "virtual_machine",
        assigned_to_private_ip: "10.6.76.205",
    },
    {
        id: 12344,
        address: "176.112.148.205",
        user_id: 328,
        billing_account_id: defaultBillingAccountId,
        type: "public",
        enabled: true,
        created_at: "2023-01-15 11:53:38",
        updated_at: "2021-01-15 11:53:39",
        uuid: "e43afc4b-da22-42c4-afc2-fe78b05568f3",
        is_deleted: false,
        is_ipv6: false,
        name: "IP unassigned in Jõhvi",
    },
];

export function generateIp(): IpAddress {
    const base = ipList1[0];
    let result = {
        ...base,
        created_at: randomPastDateTime(),
        updated_at: currentDateTime(),
        uuid: randomUuid(),
        name: randomTitle(),
    };

    executeWithProbability(0.7, () => {
        result = {
            ...result,
            // XXX: this generates invalid data (linking to non-existing resources)
            assigned_to: randomUuid(),
            assigned_to_resource_type: randomElement(["virtual_machine", "service", "load_balancer"]),
            assigned_to_private_ip: randomPrivateIpv4(),
        };
    });
    return result;
}
