import type { BillingAccountUsage } from "@warrenio/api-spec/spec.oats.gen";
import { defaultBillingAccountId } from "./billingAccountIds.ts";

export const billingAccountUsageExample: BillingAccountUsage[] = [
    {
        uuid: "e48452cc-99bb-4c97-8fc5-d78ea8b8f97c",
        owner_uuid: "7d80aab8-de52-43a1-98e7-3c23a4bcd571",
        billing_account_id: defaultBillingAccountId,
        user_id: 136,
        hours: 61,
        uptime_types: ["REPLICA_BACKUP"],
        configurations: [
            {
                is_owned_by_service: false,
                vm_name: "ubuntu",
                size_GB: 30,
                is_attached: true,
            },
        ],
        description: "ubuntu, 30 GB backup",
        price: 0.0002,
        cost: 0.366,
        price_unit: "h",
        _technical: false,
    },
    {
        uuid: "b883f408-e86a-4350-b0fd-adc73635220e",
        owner_uuid: "7d80aab8-de52-43a1-98e7-3c23a4bcd571",
        billing_account_id: defaultBillingAccountId,
        user_id: 136,
        hours: 61,
        uptime_types: ["REPLICA_BACKUP"],
        configurations: [
            {
                is_owned_by_service: false,
                vm_name: "ubuntu",
                size_GB: 30,
                is_attached: true,
            },
        ],
        description: "ubuntu, 30 GB backup",
        price: 0.0002,
        cost: 0.366,
        price_unit: "h",
        _technical: false,
    },
    {
        uuid: "32ebd6f9-54fd-4ab4-9060-b10792e45fe7",
        owner_uuid: "7d80aab8-de52-43a1-98e7-3c23a4bcd571",
        billing_account_id: defaultBillingAccountId,
        user_id: 136,
        hours: 61,
        uptime_types: ["REPLICA_BACKUP"],
        configurations: [
            {
                is_owned_by_service: false,
                vm_name: "ubuntu",
                size_GB: 30,
                is_attached: true,
            },
        ],
        description: "ubuntu, 30 GB backup",
        price: 0.0002,
        cost: 0.366,
        price_unit: "h",
        _technical: false,
    },
    {
        uuid: "52442240-d9a4-4d9d-84d7-b4ea78993dac",
        owner_uuid: "7d80aab8-de52-43a1-98e7-3c23a4bcd571",
        billing_account_id: defaultBillingAccountId,
        user_id: 136,
        hours: 61,
        uptime_types: ["REPLICA_BACKUP"],
        configurations: [
            {
                is_owned_by_service: false,
                vm_name: "ubuntu",
                size_GB: 30,
                is_attached: true,
            },
        ],
        description: "ubuntu, 30 GB backup",
        price: 0.0002,
        cost: 0.366,
        price_unit: "h",
        _technical: false,
    },
    {
        uuid: "d29f2217-0c42-4e75-9ef9-0714d4a22dca",
        owner_uuid: "7d80aab8-de52-43a1-98e7-3c23a4bcd571",
        billing_account_id: defaultBillingAccountId,
        user_id: 136,
        hours: 61,
        uptime_types: ["REPLICA_SNAPSHOT"],
        configurations: [
            {
                is_owned_by_service: false,
                vm_name: "ubuntu",
                size_GB: 30,
                is_attached: true,
            },
        ],
        description: "ubuntu, 30 GB snapshot",
        price: 0.0002,
        cost: 0.366,
        price_unit: "h",
        _technical: false,
    },
];
