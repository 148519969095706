import type { BillingAccountCard, BillingAccountCardData } from "@warrenio/api-spec/spec.oats.gen";
import {
    defaultBillingAccountId,
    preClearBillingAccountId,
    preRecurringBillingAccountId,
} from "./billingAccountIds.ts";

export function makeAdditionalCardData(data: BillingAccountCardData) {
    return JSON.stringify(data);
}

export const exampleMasterCardId = 1198;
export const exampleVisaId = 1851;

const makeCards = (billing_account_id: number, cardIdIncrement: number): BillingAccountCard[] => [
    {
        additional_data: makeAdditionalCardData({
            id: "pm_1JtZirE0TT1OxJzqnP1CmGkX",
            expire_month: 12,
            expire_year: 2023,
            last4: "5100",
            card_type: "mastercard",
            card_holder: "Disco",
            type: "creditcard",
            processor_data: {
                id: "pm_1JtZirE0TT1OxJzqnP1CmGkX",
                object: "payment_method",
                billing_details: {
                    address: { city: null, country: null, line1: null, line2: null, postal_code: null, state: null },
                    email: null,
                    name: "Disco",
                    phone: null,
                },
                card: {
                    brand: "mastercard",
                    checks: { address_line1_check: null, address_postal_code_check: null, cvc_check: "pass" },
                    country: "US",
                    exp_month: 12,
                    exp_year: 2023,
                    fingerprint: "bg7pEwSGBdsbbfO4",
                    funding: "prepaid",
                    generated_from: null,
                    last4: "5100",
                    networks: { available: ["mastercard"], preferred: null },
                    three_d_secure_usage: { supported: true },
                    wallet: null,
                },
                created: 1636385125,
                customer: "cus_G81UsP8341Ou5R",
                livemode: false,
                metadata: {},
                type: "card",
            },
        }),
        billing_account_id,
        billing_account_processor_identifier_id: 501,
        created: 1636385117,
        id: exampleMasterCardId + cardIdIncrement,
        identifier: "pm_1JtZirE0TT1OxJzqnP1CmGkX",
        is_deleted: false,
        is_primary: true,
        is_verification_pending: false,
        is_verified: true,
        valid_thru: 1703980800,
    },
    {
        additional_data: makeAdditionalCardData({
            id: "pm_1PGJ2JE0TT1OxJzqfIpEAOhY",
            expire_month: 12,
            expire_year: 2034,
            last4: "4242",
            card_type: "visa",
            card_holder: "Mr. Card Holder",
            type: "creditcard",
            processor_data: {
                id: "pm_1PGJ2JE0TT1OxJzqfIpEAOhY",
                object: "payment_method",
                allow_redisplay: "unspecified",
                billing_details: {
                    address: { city: null, country: null, line1: null, line2: null, postal_code: "12345", state: null },
                    email: null,
                    name: "Mr. Card Holder",
                    phone: null,
                },
                card: {
                    brand: "visa",
                    checks: { address_line1_check: null, address_postal_code_check: "pass", cvc_check: "pass" },
                    country: "US",
                    display_brand: "visa",
                    exp_month: 12,
                    exp_year: 2034,
                    fingerprint: "NDkWtRfVnuX2exPf",
                    funding: "credit",
                    generated_from: null,
                    last4: "4242",
                    networks: { available: ["visa"], preferred: null },
                    three_d_secure_usage: { supported: true },
                    wallet: null,
                },
                created: 1715684208,
                customer: "cus_G81UsP8341Ou5R",
                livemode: false,
                metadata: {},
                type: "card",
            },
        }),
        billing_account_id,
        billing_account_processor_identifier_id: 501,
        created: 1715684208,
        id: exampleVisaId + cardIdIncrement,
        identifier: "pm_1PGJ2JE0TT1OxJzqfIpEAOhY",
        is_deleted: false,
        is_primary: false,
        is_verification_pending: false,
        is_verified: true,
        valid_thru: 2051136000,
    },
];

export const billingAccountCardsExample = [
    ...makeCards(defaultBillingAccountId, 10),
    ...makeCards(preClearBillingAccountId, 0),
    ...makeCards(preRecurringBillingAccountId, 20),
];
