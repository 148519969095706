import type { Vpc } from "@warrenio/api-spec/spec.oats.gen";
import { executeWithProbability } from "../executeWithProbability.ts";
import {
    currentDateTime,
    randomElement,
    randomPastDateTime,
    randomSubnetIpv6,
    randomTitle,
    randomUuid,
} from "../randomTypes.ts";
import { jhvVmId, ubuntuId, windowsId } from "./vmIds.ts";

export const vpc1Id = "a748335a-6c87-498f-80cb-3dae0becb59d";
export const vpcNoNameId = "15de427d-2c2c-433e-ad16-d7b07c3483d6";

export const vpcList1: Vpc[] = [
    {
        vlan_id: 11666,
        subnet: "10.46.191.0/24",
        name: "Tallinn Network 1",
        created_at: "2022-02-04 15:47:59",
        updated_at: "2024-03-13 12:47:53",
        uuid: vpc1Id,
        type: "private",
        is_default: true,
        vm_uuids: [windowsId, ubuntuId],
        resources_count: 2,
        subnet_ipv6: "2a05:1cc0:10:3::5d:0/112",
    },
    // No resources
    {
        vlan_id: 28502,
        subnet: "10.112.197.0/24",
        name: "Tallinn Network 2",
        created_at: "2024-03-11 11:52:46",
        updated_at: "2024-03-11 11:52:46",
        uuid: "fe8b42eb-05ce-44e7-8122-e36b63ee75d0",
        type: "private",
        is_default: false,
        vm_uuids: [],
        resources_count: 0,
        subnet_ipv6: "2a05:1cc0:10:3::19a:0/112",
    },
    // No name
    {
        vlan_id: 1555,
        subnet: "10.7.25.0/24",
        name: "",
        created_at: "2021-12-02 11:06:48",
        updated_at: "2024-03-11 10:16:41",
        uuid: vpcNoNameId,
        type: "private",
        is_default: false,
        vm_uuids: [],
        resources_count: 1,
        subnet_ipv6: "2a05:1cc0:10:3::56:0/112",
    },
];

export const vpcList2: Vpc[] = [
    {
        vlan_id: 1447,
        subnet: "10.6.172.0/24",
        name: "Jõhvi Network 1",
        created_at: "2024-03-11 11:59:29",
        updated_at: "2024-03-13 12:47:53",
        uuid: "c9010197-7204-4aca-9c7b-30d130f8128e",
        type: "private",
        is_default: false,
        vm_uuids: [jhvVmId],
        resources_count: 1,
        subnet_ipv6: "2a05:1cc0:10:3::19d:0/112",
    },
    {
        vlan_id: 13914,
        subnet: "10.55.144.0/24",
        name: "Jõhvi Network 2",
        created_at: "2024-03-12 14:59:28",
        updated_at: "2024-03-12 14:59:28",
        uuid: "8b1be00c-96c8-4751-80df-f6cdc2819010",
        type: "private",
        is_default: false,
        vm_uuids: [],
        resources_count: 0,
        subnet_ipv6: "2a05:1cc0:10:3::1a3:0/112",
    },
];

export function generateNetwork(vpcList: Vpc[]): Vpc {
    let base = vpcList[0]; // with resources
    executeWithProbability(0.5, () => {
        base = vpcList[1]; // without resources
    });
    return {
        ...base,
        created_at: randomPastDateTime(),
        updated_at: currentDateTime(),
        uuid: randomUuid(),
        name: randomTitle(),
        type: randomElement(["public", "private"]),
        subnet_ipv6: randomSubnetIpv6(),
        is_default: false,
    };
}

export function setDefaultNetwork(vpc_list: Vpc[]): Vpc[] {
    const result = [...vpc_list];
    const randomIndex = Math.floor(Math.random() * vpc_list.length);
    result[randomIndex].is_default = true;
    return result;
}
