import type { AccessDelegation, AccessImpersonation, ApiToken } from "@warrenio/api-spec/spec.oats.gen";
import {
    randomAlphaNumeric,
    randomArray,
    randomBigInt,
    randomBoolean,
    randomEmail,
    randomInt,
    randomPastDateTime,
    randomTitle,
    randomUuid,
} from "../randomTypes.ts";
import { defaultBillingAccountId, notAvailableBillingAccountId } from "./billingAccountIds.ts";

export const globalTokenId = 111;
export const restrictedTokenId = 222;
export const restrictedTokenIdWithNABA = 333; // N/A BA

export const accessTokensExample: ApiToken[] = [
    {
        billing_account_id: 0,
        consumer_id: "c30110aa-f5ed-4d95-93c7-ee45a0e52ee8",
        created_at: "2020-06-18 12:17:45",
        description: "Token: Global",
        id: globalTokenId,
        kong_id: "809dd57d-ed24-4fdc-84f5-6b4562b5cbf7",
        restricted: false,
        token: "QvxU5SHqfdasfasdfasdfHz9MGPfSucjv6RIZd",
        user_id: 208,
    },
    {
        billing_account_id: defaultBillingAccountId,
        consumer_id: "c30110aa-f5ed-4d95-93c7-ee45a0e52ee8",
        created_at: "2023-08-03 11:45:39",
        description: "Token: Restricted",
        id: restrictedTokenId,
        kong_id: "f011ac8e-34f6-470f-ba3e-bae4abd8d635",
        restricted: true,
        token: "4SPKwJE6Vr5agdfdfdasfanuCTxCKRl",
        updated_at: "2023-08-03 11:50:04",
        user_id: 208,
    },
    {
        billing_account_id: notAvailableBillingAccountId,
        consumer_id: "c30110aa-f5ed-4d95-93c7-ee45a0e52ee8",
        created_at: "2023-08-03 10:45:39",
        description: "Token: Restricted (N/A BA)",
        id: restrictedTokenIdWithNABA,
        kong_id: "f011ac8e-34f6-470f-ba3e-bae4abd8d634",
        restricted: true,
        token: "4SPKwJE6Vr5adsfdfdasfanuCTxCKRl",
        updated_at: "2023-08-03 10:50:04",
        user_id: 208,
    },
];

export function generateApiToken(): ApiToken {
    const base = accessTokensExample[0];
    const restricted = randomBoolean();
    return {
        ...base,
        billing_account_id: restricted ? defaultBillingAccountId : 0,
        created_at: randomPastDateTime(),
        description: randomTitle(),
        id: randomBigInt(),
        kong_id: randomUuid(),
        restricted,
        token: randomAlphaNumeric(36),
    };
}

// Conflicting IDs for "fun"
export const globalDelegationId = 111;
export const restrictedDelegationId = 222;
export const restrictedDelegationIdWithNABA = 333; // N/A BA

export const accessDelegationExample: AccessDelegation[] = [
    {
        billing_account_id: 0,
        created_at: "2021-12-28 08:50:15",
        id: globalDelegationId,
        impersonations: [
            {
                created_at: "2023-10-06 13:27:26",
                grantee_username: "not+accepted-global@example.com",
                is_accepted: false,
                uuid: "ffc8d59b-db91-4fe9-a29a-0591669d6e82",
            },
            {
                accepted_at: "2022-01-20 14:11:30",
                created_at: "2022-01-20 07:28:35",
                grantee_id: 136,
                grantee_username: "accepted+global@oyenetwork.com",
                is_accepted: true,
                uuid: "001f03d3-afa3-4061-ab03-c1f996427a91",
            },
        ],
        name: "Delegation: Global",
        owner_id: 136,
    },
    {
        billing_account_id: defaultBillingAccountId,
        created_at: "2024-02-22 18:15:21",
        id: restrictedDelegationId,
        impersonations: [
            {
                created_at: "2024-02-22 18:15:21",
                grantee_username: "not-accepted+restricted@example.com",
                is_accepted: false,
                uuid: "7f1ea68b-0179-49e3-a20f-3522cb255e86",
            },
            {
                created_at: "2024-02-22 18:15:21",
                grantee_username: "accepted+restricted@example.com",
                is_accepted: true,
                uuid: "19776263-4824-4bfd-9ab5-051e002d56ef",
            },
        ],
        name: "Delegation: Restricted",
        owner_id: 136,
        updated_at: "2024-02-22 18:15:21",
    },
    {
        billing_account_id: notAvailableBillingAccountId,
        created_at: "2024-02-22 19:15:21",
        id: restrictedDelegationIdWithNABA,
        impersonations: [],
        name: "Delegation: Restricted (N/A BA)",
        owner_id: 136,
        updated_at: "2024-02-22 19:15:21",
    },
];

export function generateAccessDelegation(): AccessDelegation {
    const base = accessDelegationExample[0];
    return {
        ...base,
        billing_account_id: randomBoolean() ? defaultBillingAccountId : 0,
        created_at: randomPastDateTime(),
        id: randomBigInt(),
        impersonations: randomArray(randomInt(10), () => ({
            created_at: randomPastDateTime(),
            grantee_username: randomEmail(),
            is_accepted: randomBoolean(),
            uuid: randomUuid(),
        })),
        name: randomTitle(),
    };
}

export const accessImpersonationsExample: AccessImpersonation[] = [
    {
        uuid: "a56aa424-f3b8-4e1c-9fcf-2a9e3f19b461",
        created_at: "2021-11-23 11:54:32",
        is_accepted: true,
        access_owner: "kadri@oyenetwork.com",
        accepted_at: "2021-11-24 08:00:39",
    },
    {
        uuid: "3a4cb2be-69ea-48dc-bb70-0d64ca59e398",
        created_at: "2024-04-12 09:29:38",
        is_accepted: false,
        access_owner: "marko+6@oyenetwork.com",
        billing_account_name: "Konto",
    },
    {
        uuid: "e5fd8a88-6d26-46d4-8989-1b5a0df9a10f",
        created_at: "2024-02-26 13:12:20",
        is_accepted: false,
        access_owner: "kadri+shared-test@oyenetwork.com",
        billing_account_name: "Limited",
    },
];
