let nextId = 1001;

export function genId(): number {
    return nextId++;
}

export const defaultBillingAccountId = genId();
export const preClearBillingAccountId = genId();
export const preRecurringBillingAccountId = genId();

export const notAvailableBillingAccountId = 555555; // for N/A BA test cases
