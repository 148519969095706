import type { PricingHistory } from "@warrenio/api-spec/spec.oats.gen";

export const pricingHistoryExample: PricingHistory = {
    "1970-01": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 1,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            price_per_unit: 0.00967,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00845,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.00487,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0003,
                            gigabytes_from: 81,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.005479452054794521,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00214,
                            description: "Object storage",
                            price_per_unit_month: 1.5622,
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00821,
                            num_cpus_from: 1,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            price_per_unit: 0.00976,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3-3 CPUs",
                        },
                        {
                            price_per_unit: 0.010958904109589041,
                            num_cpus_from: 4,
                            description: "Virtual Machine 4+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00475,
                            gigabytes_from: 0.5,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            price_per_unit: 0.01197,
                            gigabytes_from: 3,
                            description: "Virtual Machine 3-3 GiB RAM",
                        },
                        {
                            price_per_unit: 0.012328767123287671,
                            gigabytes_from: 4,
                            description: "Virtual Machine 4+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00016,
                            gigabytes_from: 1,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.00019,
                            gigabytes_from: 81,
                            description: "Backup of disk 81-90 GiB",
                        },
                        {
                            price_per_unit: 0.0002054794520547945,
                            gigabytes_from: 91,
                            description: "Backup of disk 91+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            price_per_unit: 0.00016,
                            gigabytes_from: 1,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.00019,
                            gigabytes_from: 81,
                            description: "Snapshot of disk 81-90 GiB",
                        },
                        {
                            price_per_unit: 0.0002054794520547945,
                            gigabytes_from: 91,
                            description: "Snapshot of disk 91+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.005479452054794521,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
    },
    "2022-07": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 1,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            price_per_unit: 0.00967,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00845,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.00487,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0003,
                            gigabytes_from: 81,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.005479452054794521,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00214,
                            description: "Object storage",
                            price_per_unit_month: 1.5622,
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00821,
                            num_cpus_from: 1,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            price_per_unit: 0.00976,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3-3 CPUs",
                        },
                        {
                            price_per_unit: 0.010958904109589041,
                            num_cpus_from: 4,
                            description: "Virtual Machine 4+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00475,
                            gigabytes_from: 0.5,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            price_per_unit: 0.01197,
                            gigabytes_from: 3,
                            description: "Virtual Machine 3-3 GiB RAM",
                        },
                        {
                            price_per_unit: 0.012328767123287671,
                            gigabytes_from: 4,
                            description: "Virtual Machine 4+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00016,
                            gigabytes_from: 1,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.00019,
                            gigabytes_from: 81,
                            description: "Backup of disk 81-90 GiB",
                        },
                        {
                            price_per_unit: 0.0002054794520547945,
                            gigabytes_from: 91,
                            description: "Backup of disk 91+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            price_per_unit: 0.00016,
                            gigabytes_from: 1,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.00019,
                            gigabytes_from: 81,
                            description: "Snapshot of disk 81-90 GiB",
                        },
                        {
                            price_per_unit: 0.0002054794520547945,
                            gigabytes_from: 91,
                            description: "Snapshot of disk 91+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.005479452054794521,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
    },
    "2022-08": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 1,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0.4,
                            description: "Virtual Machine 0.4-2 GiB RAM",
                        },
                        {
                            price_per_unit: 0.00487,
                            gigabytes_from: 3,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.05,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
    },
    "2022-09": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 1,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0.4,
                            description: "Virtual Machine 0.4-2 GiB RAM",
                        },
                        {
                            price_per_unit: 0.00487,
                            gigabytes_from: 3,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.005,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
    },
    "2022-11": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            description: "Virtual Machine GiB RAM",
                            gigabytes_from: 1,
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0003,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.005,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
    },
    "2022-12": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0003,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.005,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
    },
    "2023-02": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.005,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
    },
    "2023-03": {
        THB: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.005,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:00000000-0000-0000-0000-000167bb0a39": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.0721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.0956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.0845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.005,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:00000000-0000-0000-0000-000167bb0a39": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:d2fc6881-9316-46d7-b515-b6cddaeee927": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
    },
    "2023-04": {
        THB: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.005,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:00000000-0000-0000-0000-000167bb0a39": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.0721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.0956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.0845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
        USD: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.005,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:00000000-0000-0000-0000-000167bb0a39": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.0721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.0956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.0845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.005,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:00000000-0000-0000-0000-000167bb0a39": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:d2fc6881-9316-46d7-b515-b6cddaeee927": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
        IDR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 25.685,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 51.37,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 25.685,
                            description: "Virtual Machine 1-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 51.37,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.856,
                            gigabytes_from: 0,
                            description: "Virtual Machine disk less than 81 GiB",
                        },
                        {
                            price_per_unit: 1.712,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            price_per_unit: 0.856,
                            gigabytes_from: 0,
                            description: "Backup of disk less than 81 GiB",
                        },
                        {
                            price_per_unit: 1.712,
                            gigabytes_from: 81,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            price_per_unit: 0.856,
                            gigabytes_from: 0,
                            description: "Snapshot of disk less than 81 GiB",
                        },
                        {
                            price_per_unit: 1.712,
                            gigabytes_from: 81,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.694,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 34.247,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 205.48,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            sgp01: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 25.685,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 56.507,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 25.685,
                            description: "Virtual Machine 1-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 56.507,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.856,
                            gigabytes_from: 0,
                            description: "Virtual Machine disk less than 81 GiB",
                        },
                        {
                            price_per_unit: 1.884,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            price_per_unit: 0.856,
                            gigabytes_from: 0,
                            description: "Backup of disk less than 81 GiB",
                        },
                        {
                            price_per_unit: 1.884,
                            gigabytes_from: 81,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            price_per_unit: 0.856,
                            gigabytes_from: 0,
                            description: "Snapshot of disk less than 81 GiB",
                        },
                        {
                            price_per_unit: 1.884,
                            gigabytes_from: 81,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.694,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 34.247,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 205.48,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            jkt03: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 25.685,
                            num_cpus_from: 1,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            price_per_unit: 56.507,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 25.685,
                            gigabytes_from: 1,
                            description: "Virtual Machine 1-2 GiB RAM",
                        },
                        {
                            price_per_unit: 56.507,
                            gigabytes_from: 3,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.856,
                            gigabytes_from: 0,
                            description: "Virtual Machine disk less than 81 GiB",
                        },
                        {
                            price_per_unit: 1.884,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            price_per_unit: 0.856,
                            gigabytes_from: 0,
                            description: "Backup of disk less than 81 GiB",
                        },
                        {
                            price_per_unit: 1.884,
                            gigabytes_from: 81,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            price_per_unit: 0.856,
                            gigabytes_from: 0,
                            description: "Snapshot of disk less than 81 GiB",
                        },
                        {
                            price_per_unit: 1.884,
                            gigabytes_from: 81,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.694,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 34.247,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 205.48,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
    },
    "2023-08": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.007223,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.005,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:00000000-0000-0000-0000-000167bb0a39": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:d2fc6881-9316-46d7-b515-b6cddaeee927": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
    },
    "2023-09": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.007224,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0,
                            gigabytes_from: 0,
                            description: "Virtual Machine disk 0 GiB",
                        },
                        {
                            price_per_unit: 0.000234,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.00065,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0,
                            gigabytes_from: 0,
                            description: "Virtual Machine disk 0 GiB",
                        },
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.005,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:00000000-0000-0000-0000-000167bb0a39": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0,
                            gigabytes_from: 0,
                            description: "Virtual Machine disk 0 GiB",
                        },
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:d2fc6881-9316-46d7-b515-b6cddaeee927": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0,
                            gigabytes_from: 0,
                            description: "Virtual Machine disk 0 GiB",
                        },
                        {
                            price_per_unit: 0.004,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.01,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.004,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.006,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.004,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.006,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
    },
    "2023-10": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.007224,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.000234,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.00065,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00274,
                            description: "Assigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00434,
                            description: "Assigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.005,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:00000000-0000-0000-0000-000167bb0a39": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 5.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:d2fc6881-9316-46d7-b515-b6cddaeee927": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.004,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.01,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.004,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.006,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.004,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.006,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0003,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
    },
    "2023-11": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.007224,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.000234,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.00065,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00111,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Assigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.005,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:00000000-0000-0000-0000-000167bb0a39": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 5.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:d2fc6881-9316-46d7-b515-b6cddaeee927": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.004,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.01,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.004,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.006,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.004,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.006,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0003,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00234,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
    },
    "2024-01": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.007224,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.000234,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.00065,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00814,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00111,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00534,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Assigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.005,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:00000000-0000-0000-0000-000167bb0a39": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
            ],
            "host_pool:d2fc6881-9316-46d7-b515-b6cddaeee927": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.004,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.01,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.004,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.006,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.004,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.006,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0003,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
            ],
        },
    },
    "2024-04": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.007224,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.000234,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.00065,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0003424,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00787,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00274,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00274,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00287,
                            description: "Assigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.015,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:00000000-0000-0000-0000-000167bb0a39": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [],
                },
            ],
            "host_pool:d2fc6881-9316-46d7-b515-b6cddaeee927": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.004,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.01,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.004,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.006,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.004,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.006,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0003,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [],
                },
            ],
        },
    },
    "2024-09": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.007224,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.000234,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.00065,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0003424,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00787,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00274,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00274,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00287,
                            description: "Assigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.015,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:00000000-0000-0000-0000-000167bb0a39": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [],
                },
            ],
            "host_pool:d2fc6881-9316-46d7-b515-b6cddaeee927": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.004,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.01,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.004,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.006,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.004,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.006,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0003,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [],
                },
            ],
        },
        THB: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.01,
                            num_cpus_from: 1,
                            description: "Virtual Machine CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.02,
                            gigabytes_from: 0.5,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.03,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            price_per_unit: 0.04,
                            gigabytes_from: 1,
                            description: "Backup of disk GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            price_per_unit: 0.05,
                            gigabytes_from: 1,
                            description: "Snapshot of disk GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.06,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.07,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.08,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.09,
                            description: "Assigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.1,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
        },
    },
    "2025-09": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.007224,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.000234,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.00065,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0003424,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00748,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00787,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00274,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.003,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            tll: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            num_cpus_from: 1,
                            price_per_unit: 0.00821,
                            description: "Virtual Machine 1-2 CPUs",
                        },
                        {
                            num_cpus_from: 3,
                            price_per_unit: 0.00976,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            gigabytes_from: 0.5,
                            price_per_unit: 0.00475,
                            description: "Virtual Machine 0.5-2 GiB RAM",
                        },
                        {
                            gigabytes_from: 3,
                            price_per_unit: 0.01197,
                            description: "Virtual Machine 3+ GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.00016,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.00019,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00543,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.00632,
                            description: "Object storage",
                        },
                    ],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00274,
                            description: "Unassigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [
                        {
                            price_per_unit: 0.00287,
                            description: "Assigned floating IP",
                        },
                    ],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [
                        {
                            price_per_unit: 0.015,
                            description: "Load balancer",
                        },
                    ],
                },
            ],
            "host_pool:00000000-0000-0000-0000-000167bb0a39": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0002,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.0005,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.0002,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.0003,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [],
                },
            ],
            "host_pool:d2fc6881-9316-46d7-b515-b6cddaeee927": [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.00721,
                            num_cpus_from: 0,
                            description: "Virtual Machine less than 3 CPUs",
                        },
                        {
                            price_per_unit: 0.00956,
                            num_cpus_from: 3,
                            description: "Virtual Machine 3+ CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.00845,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.004,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk 1-80 GiB",
                        },
                        {
                            price_per_unit: 0.01,
                            gigabytes_from: 81,
                            description: "Virtual Machine disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.004,
                            description: "Backup of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.006,
                            description: "Backup of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            gigabytes_from: 1,
                            price_per_unit: 0.004,
                            description: "Snapshot of disk 1-80 GiB",
                        },
                        {
                            gigabytes_from: 81,
                            price_per_unit: 0.006,
                            description: "Snapshot of disk 81+ GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [
                        {
                            price_per_unit: 0.0003,
                            description: "Windows license (per CPU)",
                        },
                    ],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [],
                },
            ],
        },
    },
    "2026-10": {
        EUR: {
            DEFAULT: [
                {
                    resource_type: "CPU",
                    resource_prices: [
                        {
                            price_per_unit: 0.009224,
                            num_cpus_from: 0,
                            description: "Virtual Machine CPUs",
                        },
                    ],
                },
                {
                    resource_type: "RAM",
                    resource_prices: [
                        {
                            price_per_unit: 0.01245,
                            gigabytes_from: 0,
                            description: "Virtual Machine GiB RAM",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE",
                    resource_prices: [
                        {
                            price_per_unit: 0.002234,
                            gigabytes_from: 1,
                            description: "Virtual Machine disk GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_BACKUP",
                    resource_prices: [
                        {
                            price_per_unit: 0.0033424,
                            gigabytes_from: 1,
                            description: "Backup of disk GiB",
                        },
                    ],
                },
                {
                    resource_type: "BLOCK_STORAGE_SNAPSHOT",
                    resource_prices: [
                        {
                            price_per_unit: 0.0022,
                            gigabytes_from: 1,
                            description: "Snapshot of disk GiB",
                        },
                    ],
                },
                {
                    resource_type: "WINDOWS_LICENSE",
                    resource_prices: [],
                },
                {
                    resource_type: "OBJECT_STORAGE",
                    resource_prices: [],
                },
                {
                    resource_type: "UNASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "ASSIGNED_FLOATING_IP",
                    resource_prices: [],
                },
                {
                    resource_type: "LOAD_BALANCER",
                    resource_prices: [],
                },
            ],
        },
    },
};
