import type { LoadBalancer } from "@warrenio/api-spec/spec.oats.gen";
import { executeWithProbability } from "../executeWithProbability.ts";
import { currentDateTime, randomPastDateTime, randomPrivateIpv4, randomTitle, randomUuid } from "../randomTypes.ts";
import { defaultBillingAccountId } from "./billingAccountIds.ts";
import { lb1Id } from "./loadBalancersIds.ts";
import { ubuntuId } from "./vmIds.ts";
import { vpc1Id, vpcNoNameId } from "./vpcNetworksExample.ts";

const user_id = 328;

export const lbList1: LoadBalancer[] = [
    {
        uuid: lb1Id,
        display_name: "Balancer (VM target)",
        user_id,
        billing_account_id: defaultBillingAccountId,
        created_at: "2022-08-26 11:26:36",
        updated_at: "2022-08-26 11:26:36",
        is_deleted: false,
        deleted_at: null,
        private_address: "10.3.119.250",
        network_uuid: vpc1Id,
        forwarding_rules: [
            {
                protocol: "TCP",
                uuid: "b34c1a68-6264-42b3-8a14-7d444828baf3",
                created_at: "2022-08-26 11:26:36",
                source_port: 80,
                target_port: 80,
                settings: {
                    connection_limit: 10000,
                    session_persistence: "SOURCE_IP",
                },
            },
            {
                protocol: "TCP",
                uuid: "d1b9b991-774d-42d2-98fa-6874f8220dd5",
                created_at: "2022-08-26 11:26:36",
                source_port: 80,
                target_port: 80,
                settings: {
                    connection_limit: 10000,
                    session_persistence: "SOURCE_IP",
                },
            },
        ],
        targets: [
            {
                created_at: "2024-03-12 11:25:14",
                target_uuid: ubuntuId,
                target_type: "vm",
                target_ip_address: "10.3.119.132",
            },
        ],
    },
    {
        uuid: "2c9b4d3f-c711-4b5b-82d6-0c8bbf48a045",
        display_name: "Balancer (no targets)",
        user_id,
        billing_account_id: defaultBillingAccountId,
        created_at: "2022-08-26 07:42:38",
        updated_at: "2024-03-11 10:08:47",
        is_deleted: false,
        deleted_at: null,
        private_address: "10.3.119.251",
        network_uuid: vpcNoNameId,
        forwarding_rules: [
            {
                protocol: "TCP",
                uuid: "07f693b0-9db7-4aab-ad09-91725be46dcc",
                created_at: "2022-08-26 07:42:38",
                source_port: 80,
                target_port: 80,
                settings: {
                    connection_limit: 10000,
                    session_persistence: "SOURCE_IP",
                },
            },
            {
                protocol: "TCP",
                uuid: "aaf84ac2-49a2-470c-8855-0a3c21078ae4",
                created_at: "2022-08-26 07:44:28",
                source_port: 803,
                target_port: 804,
                settings: {
                    connection_limit: 10000,
                    session_persistence: "SOURCE_IP",
                },
            },
            {
                protocol: "TCP",
                uuid: "8b30b00e-996c-444f-9248-e164d0e1dc7b",
                created_at: "2022-08-26 07:45:42",
                source_port: 8035,
                target_port: 804,
                settings: {
                    connection_limit: 10000,
                    session_persistence: "SOURCE_IP",
                },
            },
        ],
        targets: [],
    },
    // No name
    {
        uuid: "2c9b4d3f-c711-4b5b-82d6-0c8bbf48a046",
        display_name: "",
        user_id,
        billing_account_id: defaultBillingAccountId,
        created_at: "2022-08-26 07:42:38",
        updated_at: "2024-03-11 10:08:47",
        is_deleted: false,
        deleted_at: null,
        private_address: "10.3.119.252",
        network_uuid: vpcNoNameId,
        forwarding_rules: [],
        targets: [],
    },
];

export const lbList2: LoadBalancer[] = [];

export function generateLoadBalancer(): LoadBalancer {
    let base = lbList1[0];
    executeWithProbability(0.5, () => {
        base = lbList1[1]; // just for variety
    });

    return {
        ...base,
        created_at: randomPastDateTime(),
        updated_at: currentDateTime(),
        uuid: randomUuid(),
        network_uuid: randomUuid(),
        display_name: randomTitle(),
        private_address: randomPrivateIpv4(),
        forwarding_rules: base.forwarding_rules.map((fr) => ({ ...fr, uuid: randomUuid() })),
        targets: base.targets.map((t) => ({
            ...t,
            // XXX: this generates invalid data (linking to non-existing resources)
            target_uuid: randomUuid(),
            created_at: randomPastDateTime(),
            target_ip_address: randomPrivateIpv4(),
        })),
    };
}
