import type {
    MetalLease,
    MetalMachine,
    MetalOs,
    MetalOsType,
    MetalSpec,
    MetalStateHistory,
} from "@warrenio/api-spec/spec.oats.gen";
import { preRecurringBillingAccountId } from "./billingAccountIds.ts";
import { debianIcon, ubuntuIcon } from "./icons.ts";
import { mockUserId } from "./userExample.ts";

export interface MetalDbData {
    metalSpecs: MetalSpec[];
    metalOs: MetalOs[];
    metalOsTypes: MetalOsType[];
    metalMachines: MetalMachine[];
    metalLeases: MetalLease[];
    metalStates: MetalStateHistory[];
}

const updated_at = "2024-01-01 13:45:56";
const created_at = updated_at;

const acting_user_id = mockUserId;

export const metalExample: MetalDbData = {
    metalOs: [
        {
            os_id: "debian_10",
            os_type_id: "debian",
            version_title: "10",
            updated_at,
            is_published: true,
            is_deleted: false,
        },
        {
            os_id: "debian_11",
            os_type_id: "debian",
            version_title: "11",
            updated_at,
            is_published: true,
            is_deleted: false,
        },
        {
            os_id: "ubuntu_22",
            os_type_id: "ubuntu",
            version_title: "22",
            updated_at,
            is_published: true,
            is_deleted: false,
        },
        // Not published
        {
            os_id: "slackware_1",
            os_type_id: "slackware",
            version_title: "22",
            updated_at,
            is_published: false,
            is_deleted: false,
        },
    ],
    metalOsTypes: [
        {
            os_type_id: "debian",
            title: "Debian",
            icon: debianIcon,
            updated_at,
            is_deleted: false,
        },
        {
            os_type_id: "ubuntu",
            title: "Ubuntu",
            icon: ubuntuIcon,
            updated_at,
            is_deleted: false,
        },
        // Not published
        {
            os_type_id: "slackware",
            title: "Slackware",
            icon: "",
            updated_at,
            is_deleted: false,
        },
    ],

    metalSpecs: [
        {
            spec_id: 1,
            uuid: "00000000-0000-0000-0001-000000000001",
            title: "AMD Epyc 9124",
            subtitle: "Faster than a speeding bullet",
            description: `• AMD 9124, 16 Cores @ 3 GHz
• 128 GB RAM
• 2 x 1.9TB NVME
• 1OGbps NIC`,
            is_visible: true,
            is_deleted: false,
            created_at,
            updated_at,
        },
        {
            spec_id: 2,
            uuid: "00000000-0000-0000-0001-000000000002",
            title: "Intel Xeon 1234",
            subtitle: "Pay more for less",
            description: `• Intel 1234, 8 Cores @ 2 GHz
• 64 GB RAM
• 2 x 480GB NVME
• 1OGbps NIC`,
            is_visible: true,
            is_deleted: false,
            created_at,
            updated_at,
        },
        // Invisible
        {
            spec_id: 3,
            uuid: "00000000-0000-0000-0001-000000000003",
            title: "Intel 386",
            subtitle: "Old but gold",
            description: "• Intel 386, 1 Core @ 0.003 GHz",
            is_visible: false,
            is_deleted: false,
            created_at,
            updated_at,
        },
        // Unavailable
        {
            spec_id: 4,
            uuid: "00000000-0000-0000-0001-000000000004",
            title: "ARM Cortex",
            subtitle: "Not available",
            description: "• ARM Cortex, 4 Cores @ 1 GHz",
            is_visible: true,
            is_deleted: false,
            created_at,
            updated_at,
        },
    ],
    metalMachines: [
        // Clean
        {
            machine_id: 1,
            uuid: "00000000-0000-0000-0002-000000000001",
            label: "M1",
            spec_id: 1,
            current_state_id: 1,
            mac_addresses: ["00:00:5e:00:53:01"],
            ip_public_v4: "10.0.0.1",
            ip_public_v6: "2001:db8::1",
            ssh_credentials: "ssh user@example.com",
            admin_notes: "",
            created_at,
        },
        // Used
        {
            machine_id: 2,
            uuid: "00000000-0000-0000-0002-000000000002",
            label: "M2",
            spec_id: 1,
            current_state_id: 2,
            mac_addresses: ["00:00:5e:00:53:02"],
            ip_public_v4: "10.0.0.2",
            ip_public_v6: "2001:db8::2",
            ssh_credentials: "ssh another@example.com",
            admin_notes: "",
            created_at,
        },
        // Deleted
        {
            machine_id: 3,
            uuid: "00000000-0000-0000-0002-000000000003",
            label: "Legacy1",
            spec_id: 3,
            current_state_id: 4,
            mac_addresses: ["00:00:5e:00:53:03"],
            ip_public_v4: "10.0.0.200",
            ip_public_v6: null,
            ssh_credentials: null,
            admin_notes: "",
            created_at,
        },
        // Clean (spec 2)
        {
            machine_id: 4,
            uuid: "00000000-0000-0000-0002-000000000004",
            label: "M4",
            spec_id: 2,
            current_state_id: 5,
            mac_addresses: ["00:00:5e:00:53:04"],
            ip_public_v4: "10.0.0.4",
            ip_public_v6: null,
            ssh_credentials: "ssh another@example.com",
            admin_notes: "",
            created_at,
        },
    ],
    metalStates: [
        {
            state_id: 1,
            machine_id: 1,

            changed_at: updated_at,
            acting_user_id,
            action: "admin_create",
            billing_account_id: null,
            lease_id: null,
            os_id: "debian_10",
            status: "available",
        },
        {
            state_id: 2,
            machine_id: 2,

            changed_at: updated_at,
            acting_user_id,
            action: "admin_create",
            billing_account_id: preRecurringBillingAccountId,
            lease_id: 1,
            os_id: "debian_11",
            status: "in_use",
        },
        {
            state_id: 3,
            machine_id: 3,

            changed_at: updated_at,
            acting_user_id,
            action: "admin_create",
            billing_account_id: null,
            lease_id: null,
            os_id: "slackware",
            status: "available",
        },
        {
            state_id: 4,
            machine_id: 3,

            changed_at: updated_at,
            acting_user_id,
            action: "admin_update",
            billing_account_id: null,
            lease_id: null,
            os_id: "slackware",
            status: "deleted",
        },
        {
            state_id: 5,
            machine_id: 4,

            changed_at: updated_at,
            acting_user_id,
            action: "admin_create",
            billing_account_id: null,
            lease_id: null,
            os_id: "debian_11",
            status: "available",
        },
    ],
    metalLeases: [
        {
            lease_id: 1,
            machine_id: 1,

            uuid: "00000000-0000-0000-0003-000000000001",
            user_id: mockUserId,
            requested_os_id: "debian_11",
            created_at: created_at,
            display_name: "My machine",
            updated_at,
        },
    ],
};
