import type { BillingAccountCardData } from "@warrenio/api-spec/spec.oats.gen";

export const mockInvoiceCard = {
    id: "N/A",
    type: "paying_by_invoice",
    card_type: "By invoice",
    expire_month: "12",
    expire_year: "2099",
    card_holder: "mock-invoice-card@warren.io",
    is_verified: false,
} satisfies BillingAccountCardData;

export const mockCreditCard = {
    id: "N/A",
    type: "creditcard",
    card_type: "Mock card",
    expire_month: "12",
    expire_year: "2099",
    card_holder: "mock-credit-card@warren.io",
    is_verified: false,
} satisfies BillingAccountCardData;

export const mockMasterCard = {
    id: "pm_1JtZirE0TT1OxJzqnP1CmGkX",
    expire_month: 12,
    expire_year: 2023,
    last4: "5100",
    card_type: "mastercard",
    card_holder: "Disco",
    type: "creditcard",
    processor_data: {
        id: "pm_1JtZirE0TT1OxJzqnP1CmGkX",
        object: "payment_method",
        billing_details: {
            address: {
                city: null,
                country: null,
                line1: null,
                line2: null,
                postal_code: null,
                state: null,
            },
            email: null,
            name: "Disco",
            phone: null,
        },
        card: {
            brand: "mastercard",
            checks: {
                address_line1_check: null,
                address_postal_code_check: null,
                cvc_check: "pass",
            },
            country: "US",
            exp_month: 12,
            exp_year: 2023,
            fingerprint: "bg7pEwSGBdsbbfO4",
            funding: "prepaid",
            generated_from: null,
            last4: "5100",
            networks: {
                available: ["mastercard"],
                preferred: null,
            },
            three_d_secure_usage: {
                supported: true,
            },
            wallet: null,
        },
        created: 1636385125,
        customer: "cus_G81UsP8341Ou5R",
        livemode: false,
        metadata: {},
        type: "card",
    },
    is_verified: true,
} satisfies BillingAccountCardData;

export const mockVisaCard = {
    id: "card_test_5v7ikdd18hl34ntmu3x",
    expire_month: 12,
    expire_year: 2034,
    last4: "4242",
    card_type: "Visa",
    card_holder: "vfdfvfdvdv dfddss",
    type: "creditcard",
    processor_data: {
        object: "card",
        id: "card_test_5v7ikdd18hl34ntmu3x",
        livemode: false,
        location: "/customers/cust_test_5v7ikdm6877zofxaf2u/cards/card_test_5v7ikdd18hl34ntmu3x",
        deleted: false,
        street1: null,
        street2: null,
        city: null,
        state: null,
        phone_number: null,
        postal_code: null,
        country: "us",
        financing: "credit",
        bank: "JPMORGAN CHASE BANK N.A.",
        brand: "Visa",
        fingerprint: "CH0R0l1rUxI/eo45ulM4ns0xg+yel2wkINTVS1/uuQA=",
        first_digits: null,
        last_digits: "4242",
        name: "vfdfvfdvdv dfddss",
        expiration_month: 12,
        expiration_year: 2034,
        security_code_check: true,
        tokenization_method: null,
        created_at: "2023-03-23T18:18:06Z",
    },
    is_verified: true,
} satisfies BillingAccountCardData;
