import type {
    DbServiceProperties,
    ManagedService,
    ServiceSecrets,
    ServiceWhiteList,
} from "@warrenio/api-spec/spec.oats.gen";
import { defaultBillingAccountId } from "./billingAccountIds.ts";

export const serviceWhitelistExample: ServiceWhiteList = ["107.102.123.123"];

export const serviceSecretsExample: ServiceSecrets = {
    sql_pass: "z1vTiJdq9jnZ",
};

export const dbServiceDefaultProperties: Record<string, DbServiceProperties> = {
    postgresql: {
        service_ip: "10.44.62.157",
        location: "tll",
        sql_user: "wadmin",
        port: 5432,
    },
    mariadb: {
        service_ip: "10.2.189.121",
        location: "tll",
        sql_user: "wadmin",
        port: 3306,
    },
};

export const servicesList: ManagedService[] = [
    {
        billing_account_id: defaultBillingAccountId,
        created_at: "2024-06-07 07:35:26",
        display_name: "topelt",
        is_deleted: false,
        is_multi_node: true,
        prices: [
            {
                priceMultiplier: 3.0,
                resourceType: "CPU",
            },
            {
                priceMultiplier: 3.0,
                resourceType: "RAM",
            },
            {
                priceMultiplier: 3.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "main",
            },
            {
                priceMultiplier: 3.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "block",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "snapshot",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "backup",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "OBJECT_STORAGE",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "LICENSE",
                serviceNameInUptime: "windows",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "UNASSIGNED_FLOATING_IP",
            },
            {
                price_multiplier: 3.0,
                resource_type: "CPU",
            },
            {
                price_multiplier: 3.0,
                resource_type: "RAM",
            },
            {
                price_multiplier: 3.0,
                resource_type: "BLOCK_STORAGE",
            },
            {
                price_multiplier: 0.0,
                resource_type: "BLOCK_STORAGE_SNAPSHOT",
            },
            {
                price_multiplier: 0.0,
                resource_type: "BLOCK_STORAGE_BACKUP",
            },
            {
                price_multiplier: 0.0,
                resource_type: "OBJECT_STORAGE",
            },
            {
                price_multiplier: 0.0,
                resource_type: "WINDOWS_LICENSE",
            },
            {
                price_multiplier: 0.0,
                resource_type: "UNASSIGNED_FLOATING_IP",
            },
        ],
        properties: {
            ...dbServiceDefaultProperties.postgresql,
            service_ip: "10.44.62.157",
        },
        resources: [
            {
                resource_allocation: {},
                resource_id: "e69b1a79-541b-45d7-8dd0-d1993883ee58",
                resource_location: "tll",
                resource_type: "virtual_ip",
            },
            {
                resource_allocation: {
                    memory: 1024,
                    vcpu: 1,
                    status: "stopped",
                    storage: [
                        {
                            primary: true,
                            size: 30,
                            uuid: "fd9f263e-969e-496c-8612-06192a8b0982",
                            replica: [],
                        },
                    ],
                },
                resource_id: "b4c49b95-2f29-4697-97c8-230d4f896fad",
                resource_location: "tll",
                resource_type: "vm",
            },
            {
                resource_allocation: {
                    memory: 1024,
                    vcpu: 1,
                    status: "stopped",
                    storage: [
                        {
                            primary: true,
                            size: 30,
                            uuid: "228ccaf7-a374-44ae-b876-8a6bbbe2b095",
                            replica: [],
                        },
                    ],
                },
                resource_id: "0bccc906-ee08-4fcc-ae63-625f3c32dc9f",
                resource_location: "tll",
                resource_type: "vm",
            },
        ],
        service: "postgresql",
        status: "active",
        updated_at: "2024-06-07 07:40:40",
        user_id: 136,
        uuid: "1ff36434-0206-4d25-9d26-13683cba8014",
        version: "14.0",
    },
    {
        billing_account_id: defaultBillingAccountId,
        created_at: "2024-03-09 09:57:29",
        display_name: "maria",
        is_deleted: false,
        is_multi_node: false,
        prices: [
            {
                priceMultiplier: 3.0,
                resourceType: "CPU",
            },
            {
                priceMultiplier: 3.0,
                resourceType: "RAM",
            },
            {
                priceMultiplier: 3.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "main",
            },
            {
                priceMultiplier: 3.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "block",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "snapshot",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "backup",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "OBJECT_STORAGE",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "LICENSE",
                serviceNameInUptime: "windows",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "UNASSIGNED_FLOATING_IP",
            },
            {
                price_multiplier: 3.0,
                resource_type: "CPU",
            },
            {
                price_multiplier: 3.0,
                resource_type: "RAM",
            },
            {
                price_multiplier: 3.0,
                resource_type: "BLOCK_STORAGE",
            },
            {
                price_multiplier: 0.0,
                resource_type: "BLOCK_STORAGE_SNAPSHOT",
            },
            {
                price_multiplier: 0.0,
                resource_type: "BLOCK_STORAGE_BACKUP",
            },
            {
                price_multiplier: 0.0,
                resource_type: "OBJECT_STORAGE",
            },
            {
                price_multiplier: 0.0,
                resource_type: "WINDOWS_LICENSE",
            },
            {
                price_multiplier: 0.0,
                resource_type: "UNASSIGNED_FLOATING_IP",
            },
        ],
        properties: {
            ...dbServiceDefaultProperties.mariadb,
            service_ip: "10.2.189.121",
        },
        resources: [
            {
                resource_allocation: {
                    memory: 1024,
                    vcpu: 2,
                    status: "running",
                    storage: [
                        {
                            primary: true,
                            size: 20,
                            uuid: "2ed84c9e-9d96-4057-9ac9-87b5d587d322",
                            replica: [
                                {
                                    created_at: "2024-06-07 01:31:00",
                                    size: 20,
                                    type: "backup",
                                    uuid: "92746404-8c64-4b1d-9b8d-3714672d7484",
                                },
                                {
                                    created_at: "2024-06-06 01:30:58",
                                    size: 20,
                                    type: "backup",
                                    uuid: "b7362b1f-a3c6-47dd-a676-9c3a2dbbe94e",
                                },
                                {
                                    created_at: "2024-06-05 01:30:57",
                                    size: 20,
                                    type: "backup",
                                    uuid: "9a096bf1-dd1c-4f30-bc8d-5b2b10daed1c",
                                },
                                {
                                    created_at: "2024-06-04 01:30:58",
                                    size: 20,
                                    type: "backup",
                                    uuid: "95cfca2c-4ea9-4e10-81e0-37421221d1db",
                                },
                                {
                                    created_at: "2024-06-03 01:31:00",
                                    size: 20,
                                    type: "backup",
                                    uuid: "89737a0a-29f3-4938-b751-48b8b1c856b1",
                                },
                                {
                                    created_at: "2024-06-02 01:30:56",
                                    size: 20,
                                    type: "backup",
                                    uuid: "5cb818fb-11fb-4903-b0df-617d10395b9e",
                                },
                                {
                                    created_at: "2024-06-01 01:30:57",
                                    size: 20,
                                    type: "backup",
                                    uuid: "489b5fc5-bc46-4ccc-b587-19b48c94a0f7",
                                },
                            ],
                        },
                    ],
                },
                resource_id: "9c2300e3-f6e6-4958-9979-b2b08beec6c3",
                resource_location: "tll",
                resource_type: "vm",
            },
        ],
        service: "mariadb",
        status: "active",
        updated_at: "2024-05-13 13:34:50",
        user_id: 136,
        uuid: "97667611-f968-44b4-b39d-1d231c0ef936",
        version: "10.5_1",
    },
    {
        billing_account_id: defaultBillingAccountId,
        created_at: "2024-02-08 08:32:52",
        display_name: "postre",
        is_deleted: false,
        is_multi_node: true,
        prices: [
            {
                priceMultiplier: 3.0,
                resourceType: "CPU",
            },
            {
                priceMultiplier: 3.0,
                resourceType: "RAM",
            },
            {
                priceMultiplier: 3.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "main",
            },
            {
                priceMultiplier: 3.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "block",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "snapshot",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "backup",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "OBJECT_STORAGE",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "LICENSE",
                serviceNameInUptime: "windows",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "UNASSIGNED_FLOATING_IP",
            },
            {
                price_multiplier: 3.0,
                resource_type: "CPU",
            },
            {
                price_multiplier: 3.0,
                resource_type: "RAM",
            },
            {
                price_multiplier: 3.0,
                resource_type: "BLOCK_STORAGE",
            },
            {
                price_multiplier: 0.0,
                resource_type: "BLOCK_STORAGE_SNAPSHOT",
            },
            {
                price_multiplier: 0.0,
                resource_type: "BLOCK_STORAGE_BACKUP",
            },
            {
                price_multiplier: 0.0,
                resource_type: "OBJECT_STORAGE",
            },
            {
                price_multiplier: 0.0,
                resource_type: "WINDOWS_LICENSE",
            },
            {
                price_multiplier: 0.0,
                resource_type: "UNASSIGNED_FLOATING_IP",
            },
        ],
        properties: {
            ...dbServiceDefaultProperties.postgresql,
            service_ip: "10.2.189.100",
        },
        resources: [
            {
                resource_allocation: {},
                resource_id: "ba12ddc3-ef13-4ae9-a030-e0a0cadb7c75",
                resource_location: "tll",
                resource_type: "virtual_ip",
            },
            {
                resource_allocation: {
                    memory: 1024,
                    vcpu: 1,
                    status: "running",
                    storage: [
                        {
                            primary: true,
                            size: 30,
                            uuid: "8bbfe49a-b5c9-41f6-9f7a-74f866791b15",
                            replica: [
                                {
                                    created_at: "2024-06-07 01:30:50",
                                    size: 30,
                                    type: "backup",
                                    uuid: "9418b527-0bef-4fb3-8f0a-249d0529140c",
                                },
                                {
                                    created_at: "2024-06-06 01:30:49",
                                    size: 30,
                                    type: "backup",
                                    uuid: "5442ba74-6794-43d6-bd01-71eaf97572c8",
                                },
                                {
                                    created_at: "2024-06-05 01:30:48",
                                    size: 30,
                                    type: "backup",
                                    uuid: "2a0a546d-330d-4901-b231-1df45e7e3150",
                                },
                                {
                                    created_at: "2024-06-04 01:30:48",
                                    size: 30,
                                    type: "backup",
                                    uuid: "2487cc6f-5d59-4b34-b3db-305785f97421",
                                },
                                {
                                    created_at: "2024-06-03 01:30:52",
                                    size: 30,
                                    type: "backup",
                                    uuid: "6ed5a92c-78f3-4410-ba56-20708a8f080f",
                                },
                                {
                                    created_at: "2024-06-02 01:30:47",
                                    size: 30,
                                    type: "backup",
                                    uuid: "9022fb4a-7253-4764-8575-48166188fb03",
                                },
                                {
                                    created_at: "2024-06-01 01:30:48",
                                    size: 30,
                                    type: "backup",
                                    uuid: "35b0b6b1-d2c0-4d26-a883-4033abc19876",
                                },
                            ],
                        },
                    ],
                },
                resource_id: "1c74a866-7a89-498b-836e-d8bb8adcf3a0",
                resource_location: "tll",
                resource_type: "vm",
            },
            {
                resource_allocation: {
                    memory: 1024,
                    vcpu: 1,
                    status: "stopped",
                    storage: [
                        {
                            primary: true,
                            size: 30,
                            uuid: "f5446235-11da-41b9-9858-f4a91539a569",
                            replica: [
                                {
                                    created_at: "2024-06-07 01:30:45",
                                    size: 30,
                                    type: "backup",
                                    uuid: "fd1bfa83-c6a6-4e24-8dd8-85fd8a7a8aa3",
                                },
                                {
                                    created_at: "2024-06-06 01:30:42",
                                    size: 30,
                                    type: "backup",
                                    uuid: "b179dd11-10a0-45c7-8ef3-b4b179297efd",
                                },
                                {
                                    created_at: "2024-06-05 01:30:42",
                                    size: 30,
                                    type: "backup",
                                    uuid: "eb65db0e-af63-4ccf-adac-5e828a57cf4f",
                                },
                                {
                                    created_at: "2024-06-04 01:30:43",
                                    size: 30,
                                    type: "backup",
                                    uuid: "c77adae5-7f2a-4b6d-96ba-ef6c8752a696",
                                },
                                {
                                    created_at: "2024-06-03 01:30:46",
                                    size: 30,
                                    type: "backup",
                                    uuid: "6126b59c-6007-4f73-80cb-863bed67d6a7",
                                },
                                {
                                    created_at: "2024-06-02 01:30:42",
                                    size: 30,
                                    type: "backup",
                                    uuid: "5c293e55-1e29-46d8-820e-ad74f1476cb9",
                                },
                                {
                                    created_at: "2024-06-01 01:30:42",
                                    size: 30,
                                    type: "backup",
                                    uuid: "6a7917e2-05d4-4569-ae64-bd2e1791f361",
                                },
                            ],
                        },
                    ],
                },
                resource_id: "313fa4c5-5533-4548-8084-a006be1f31c0",
                resource_location: "tll",
                resource_type: "vm",
            },
        ],
        service: "postgresql",
        status: "active",
        updated_at: "2024-02-08 08:38:02",
        user_id: 136,
        uuid: "52a40551-d794-4f95-9352-70519f7d4c07",
        version: "14.0",
    },
    {
        billing_account_id: defaultBillingAccountId,
        created_at: "2024-03-22 09:45:06",
        display_name: "teine",
        is_deleted: false,
        is_multi_node: false,
        prices: [
            {
                priceMultiplier: 3.0,
                resourceType: "CPU",
            },
            {
                priceMultiplier: 3.0,
                resourceType: "RAM",
            },
            {
                priceMultiplier: 3.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "main",
            },
            {
                priceMultiplier: 3.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "block",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "snapshot",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "STORAGE",
                serviceNameInUptime: "backup",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "OBJECT_STORAGE",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "LICENSE",
                serviceNameInUptime: "windows",
            },
            {
                priceMultiplier: 0.0,
                resourceType: "UNASSIGNED_FLOATING_IP",
            },
            {
                price_multiplier: 3.0,
                resource_type: "CPU",
            },
            {
                price_multiplier: 3.0,
                resource_type: "RAM",
            },
            {
                price_multiplier: 3.0,
                resource_type: "BLOCK_STORAGE",
            },
            {
                price_multiplier: 0.0,
                resource_type: "BLOCK_STORAGE_SNAPSHOT",
            },
            {
                price_multiplier: 0.0,
                resource_type: "BLOCK_STORAGE_BACKUP",
            },
            {
                price_multiplier: 0.0,
                resource_type: "OBJECT_STORAGE",
            },
            {
                price_multiplier: 0.0,
                resource_type: "WINDOWS_LICENSE",
            },
            {
                price_multiplier: 0.0,
                resource_type: "UNASSIGNED_FLOATING_IP",
            },
        ],
        properties: {
            location: "tll",
            cluster_name: "240322378b6604",
            spec: {
                hibernation: {
                    enabled: true,
                },
                kubernetes: {
                    kubeAPIServer: {
                        requests: {
                            maxNonMutatingInflight: 400,
                            maxMutatingInflight: 200,
                        },
                        enableAnonymousAuthentication: false,
                        eventTTL: "1h0m0s",
                        logging: {
                            verbosity: 2,
                        },
                        defaultNotReadyTolerationSeconds: 300,
                        defaultUnreachableTolerationSeconds: 300,
                    },
                    kubeControllerManager: {
                        nodeCIDRMaskSize: 24,
                        nodeMonitorGracePeriod: "2m0s",
                    },
                    kubeScheduler: {
                        profile: "balanced",
                    },
                    kubeProxy: {
                        mode: "IPTables",
                        enabled: true,
                    },
                    kubelet: {
                        failSwapOn: true,
                        kubeReserved: {
                            cpu: "80m",
                            memory: "1Gi",
                            pid: "20k",
                        },
                        imageGCHighThresholdPercent: 50,
                        imageGCLowThresholdPercent: 40,
                        serializeImagePulls: true,
                    },
                    version: "1.26.13",
                    verticalPodAutoscaler: {
                        enabled: true,
                        evictAfterOOMThreshold: "10m0s",
                        evictionRateBurst: 1,
                        evictionRateLimit: -1,
                        evictionTolerance: 0.5,
                        recommendationMarginFraction: 0.15,
                        updaterInterval: "1m0s",
                        recommenderInterval: "1m0s",
                    },
                    enableStaticTokenKubeconfig: true,
                },
                maintenance: {
                    autoUpdate: {
                        kubernetesVersion: true,
                        machineImageVersion: true,
                    },
                    timeWindow: {
                        begin: "030000+0200",
                        end: "040000+0200",
                    },
                },
                networking: {
                    type: "calico",
                    providerConfig: {
                        apiVersion: "calico.networking.extensions.gardener.cloud/v1alpha1",
                        kind: "NetworkConfig",
                        typha: {
                            enabled: false,
                        },
                    },
                    pods: "10.1.0.0/16",
                    nodes: "10.22.206.0/24",
                    services: "10.2.0.0/16",
                    ipFamilies: ["IPv4"],
                },
                provider: {
                    type: "warren",
                    controlPlaneConfig: {
                        apiEndpointURL: "https://test-api.pilw.io/v1/tll",
                        apiVersion: "warren.provider.extensions.gardener.cloud/v1alpha1",
                        kind: "ControlPlaneConfig",
                        zone: "tll",
                    },
                    infrastructureConfig: {
                        apiEndpointURL: "https://test-api.pilw.io/v1/tll",
                        apiVersion: "warren.provider.extensions.gardener.cloud/v1alpha1",
                        kind: "InfrastructureConfig",
                        networks: {
                            workersConfiguration: {
                                zone: "tll",
                            },
                        },
                    },
                    workers: [
                        {
                            cri: {
                                name: "containerd",
                            },
                            name: "worker-op8s7g",
                            machine: {
                                image: {
                                    name: "ubuntu",
                                    version: "24.04",
                                },
                                architecture: "amd64",
                                vcpu: 2,
                                ram: 2048,
                            },
                            maximum: 5,
                            minimum: 1,
                            maxSurge: 1,
                            maxUnavailable: 0,
                            volume: {
                                size: "40Gi",
                            },
                            zones: ["tll"],
                            systemComponents: {
                                allow: true,
                            },
                        },
                    ],
                    workersSettings: {
                        sshAccess: {
                            enabled: true,
                        },
                    },
                },
            },
            status: {
                conditions: [
                    {
                        type: "APIServerAvailable",
                        status: "True",
                        lastTransitionTime: "2024-03-23T01:06:06Z",
                        lastUpdateTime: "2024-03-23T01:06:05Z",
                        reason: "ConditionNotChecked",
                        message: "Shoot cluster has been hibernated.",
                    },
                    {
                        type: "ControlPlaneHealthy",
                        status: "True",
                        lastTransitionTime: "2024-03-23T01:06:06Z",
                        lastUpdateTime: "2024-03-23T01:06:05Z",
                        reason: "ConditionNotChecked",
                        message: "Shoot cluster has been hibernated.",
                    },
                    {
                        type: "ObservabilityComponentsHealthy",
                        status: "True",
                        lastTransitionTime: "2024-03-23T01:06:06Z",
                        lastUpdateTime: "2024-03-23T01:06:05Z",
                        reason: "ConditionNotChecked",
                        message: "Shoot cluster has been hibernated.",
                    },
                    {
                        type: "EveryNodeReady",
                        status: "True",
                        lastTransitionTime: "2024-03-23T01:06:06Z",
                        lastUpdateTime: "2024-03-23T01:06:05Z",
                        reason: "ConditionNotChecked",
                        message: "Shoot cluster has been hibernated.",
                    },
                    {
                        type: "SystemComponentsHealthy",
                        status: "True",
                        lastTransitionTime: "2024-03-23T01:06:06Z",
                        lastUpdateTime: "2024-03-23T01:06:05Z",
                        reason: "ConditionNotChecked",
                        message: "Shoot cluster has been hibernated.",
                    },
                ],
                constraints: [
                    {
                        type: "HibernationPossible",
                        status: "True",
                        lastTransitionTime: "2024-03-23T01:06:06Z",
                        lastUpdateTime: "2024-03-22T09:53:12Z",
                        reason: "ConstraintNotChecked",
                        message: "Shoot cluster has been hibernated.",
                    },
                    {
                        type: "MaintenancePreconditionsSatisfied",
                        status: "True",
                        lastTransitionTime: "2024-03-23T01:06:06Z",
                        lastUpdateTime: "2024-03-22T09:53:12Z",
                        reason: "ConstraintNotChecked",
                        message: "Shoot cluster has been hibernated.",
                    },
                    {
                        type: "CACertificateValiditiesAcceptable",
                        status: "True",
                        lastTransitionTime: "2024-03-22T09:53:12Z",
                        lastUpdateTime: "2024-03-22T09:53:12Z",
                        reason: "ConstraintNotChecked",
                        message: "Shoot cluster has been hibernated.",
                    },
                    {
                        type: "CRDsWithProblematicConversionWebhooks",
                        status: "True",
                        lastTransitionTime: "2024-03-22T09:53:12Z",
                        lastUpdateTime: "2024-03-22T09:53:12Z",
                        reason: "ConstraintNotChecked",
                        message: "Shoot cluster has been hibernated.",
                    },
                ],
                gardener: {
                    id: "A7kSSRkRPUafPXDnOzPI3DYJUTTu8a1MD0XrDGIMdUzrBDaYCNCICKbMVXowb0KV",
                    name: "gardenlet-6695854bcb-2n5ll",
                    version: "v1.87.3",
                },
                hibernated: true,
                lastOperation: {
                    description:
                        'Shoot cannot be synced with Seed: seed is not yet ready: condition "SeedSystemComponentsHealthy" has invalid status False (expected True) due to DeploymentUnhealthy: Deployment "istio-ingress/istio-ingressgateway" is unhealthy: condition "Available" has invalid status False (expected True) due to MinimumReplicasUnavailable: Deployment does not have minimum availability.',
                    lastUpdateTime: "2024-06-07T18:53:08Z",
                    progress: 0,
                    state: "Processing",
                    type: "Reconcile",
                },
                observedGeneration: 45,
                retryCycleStartTime: "2024-06-07T01:22:35Z",
                seedName: "local",
                technicalID: "shoot--local--240322378b6604",
                uid: "2a01fdac-a2a8-4a66-8f21-03e033ffacab",
                clusterIdentity:
                    "shoot--local--240322378b6604-2a01fdac-a2a8-4a66-8f21-03e033ffacab-garden-cluster-identity",
                advertisedAddresses: [
                    {
                        name: "external",
                        url: "https://api.240322378b6604.local.external.test.pilw.gardendev.wrrnk8s.com",
                    },
                    {
                        name: "internal",
                        url: "https://api.240322378b6604.local.internal.ingress.local.test.pilw.gardendev.wrrnk8s.com",
                    },
                ],
                lastErrors: [
                    {
                        description:
                            'task "Waiting until shoot worker nodes have been reconciled" failed: Error while waiting for Worker shoot--local--240322378b6604/240322378b6604 to become ready: error during reconciliation: Error reconciling Worker: failed to generate the machine deployments: could not find machine image for ubuntu/24.04 neither in cloud profile nor in worker status',
                        taskID: "Waiting until shoot worker nodes have been reconciled",
                        lastUpdateTime: "2024-04-09T17:02:27Z",
                    },
                ],
                lastMaintenance: {
                    description:
                        'All maintenance operations successful. Worker pool "worker-op8s7g": Updated machine image "ubuntu" from "22.04" to "24.04". Reason: Automatic update of the machine image version is configured (image update strategy: major)',
                    triggeredTime: "2024-03-23T01:30:15Z",
                    state: "Succeeded",
                },
            },
        },
        resources: [
            {
                resource_allocation: {},
                resource_id: "d222d534-5859-4c4a-ae95-089862cbe14f",
                resource_location: "tll",
                resource_type: "network",
            },
            {
                resource_allocation: {},
                resource_id: "5842",
                resource_type: "kong_token",
            },
        ],
        service: "kubernetes",
        status: "active",
        updated_at: "2024-06-07 18:52:05",
        user_id: 136,
        uuid: "378b6604-5a88-4a2c-b396-636835641fe9",
        version: "1.26.13",
    },
];
