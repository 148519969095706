import type { BillingAccountHistory } from "@warrenio/api-spec/spec.oats.gen";
import { defaultBillingAccountId } from "./billingAccountIds.ts";

export const billingAccountHistoryExample: BillingAccountHistory[] = [
    {
        amount: -1.99,
        billing_account_id: defaultBillingAccountId,
        created: 1698997511,
        description: "Invoice payment",
        id: 2856,
    },
    {
        amount: -12.21,
        billing_account_id: defaultBillingAccountId,
        created: 1696319133,
        description: "Invoice payment",
        id: 2793,
    },
    {
        amount: -4.09,
        billing_account_id: defaultBillingAccountId,
        created: 1693594512,
        description: "Invoice payment",
        id: 2733,
    },
    {
        amount: -4.09,
        billing_account_id: defaultBillingAccountId,
        created: 1693594488,
        description: "Invoice payment",
        id: 2728,
    },
    {
        amount: -2.02,
        billing_account_id: defaultBillingAccountId,
        created: 1648971949,
        description: "Invoice payment",
        id: 1674,
    },
    {
        amount: -14.63,
        billing_account_id: defaultBillingAccountId,
        created: 1643874396,
        description: "Invoice payment",
        id: 1580,
    },
    {
        amount: -18.54,
        billing_account_id: defaultBillingAccountId,
        created: 1641196124,
        description: "Invoice payment",
        id: 1489,
    },
    {
        amount: -20.46,
        billing_account_id: defaultBillingAccountId,
        created: 1638517705,
        description: "Invoice payment",
        id: 1443,
    },
    {
        amount: 66,
        billing_account_id: defaultBillingAccountId,
        created: 1636128146,
        description: "Top up for account 129511, including VAT (20%) 13.2 \u20ac",
        id: 1417,
        reference: "",
    },
    {
        amount: 66,
        billing_account_id: defaultBillingAccountId,
        created: 1636124544,
        description: "Top up for account 129511, including VAT (20%) 13.2 \u20ac",
        id: 1416,
        reference: "",
    },
    {
        amount: 51,
        billing_account_id: defaultBillingAccountId,
        created: 1636120952,
        description: "Top up for account 129511, including VAT (20%) 10.2 \u20ac",
        id: 1415,
        reference: "",
    },
];
