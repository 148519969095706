import type { Campaigns } from "@warrenio/api-spec/spec.oats.gen";

export const campaignsExample: Campaigns[] = [
    // All users active
    {
        campaign_code: "allusers",
        campaign_description: "all users campaign",
        campaign_type: "all_users",
        created_at: 1715084673,
        credit_amount: 15,
        id: 64,
        is_active: true,
        is_deleted: false,
        used_count: 1,
    },
    // New users, weird code
    {
        campaign_code: "a,comma",
        campaign_description: "After Black friday",
        campaign_type: "new_users",
        created_at: 1669642465,
        credit_amount: 25,
        id: 61,
        is_active: true,
        is_deleted: false,
        used_count: 7,
    },
    // All users, Inactive
    {
        campaign_code: "4new",
        campaign_description: "Campaign for newcomers",
        campaign_type: "new_users",
        created_at: 1668690946,
        credit_amount: 11,
        id: 40,
        is_active: false,
        is_deleted: false,
        used_count: 0,
    },
    // New users, Inactive
    {
        campaign_code: "autumn2022",
        campaign_description: "20\u20ac discount for autumn signups",
        campaign_type: "new_users",
        created_at: 1666169570,
        credit_amount: 20,
        id: 38,
        is_active: false,
        is_deleted: false,
        used_count: 3,
    },
];
