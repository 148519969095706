import type { SyncStorage } from "jotai/vanilla/utils/atomWithStorage";
import { jsonLocalStorage } from "../utils/jotai/atomStorage.ts";
import { event_mockDbHotReload } from "./db.events.ts";
import type { MockDb } from "./defaultDb.ts";
import * as _defaultDb from "./defaultDb.ts";
import type { MockDbOptions } from "./mockDbVariants.ts";
import type { MswOptions } from "./msw/mswOptions.ts";

// Mutable alias for hot reload
let defaultDb = _defaultDb;

interface MockDbState {
    mockDb: MockDb | undefined;
    dirtyTimeout: ReturnType<typeof setTimeout> | undefined;
    /** Whether to store the mock DB in localStorage or reset it on every reload. */
    persistent: boolean;
}

const state: MockDbState = {
    mockDb: undefined,
    dirtyTimeout: undefined,
    persistent: false,
};

export function initMockDb({ mockDbOptions, persistentMockDbEnabled }: MswOptions) {
    state.persistent = persistentMockDbEnabled ?? false;

    if (!state.persistent) {
        console.debug("Setting up new temporary mock DB: %o", mockDbOptions);
        setMockDb(defaultDb.buildMockDb(mockDbOptions));
    }
}

/** NB: Call this function after any mutations to {@link mockDb}. */
export function markDirty() {
    if (!state.dirtyTimeout) {
        state.mockDb!.modified = true;
        state.dirtyTimeout = setTimeout(() => {
            saveDb();
            state.dirtyTimeout = undefined;
        }, 100);
    }
}

export function getMockDb(): MockDb {
    if (!state.mockDb) {
        loadDb();
    }
    return state.mockDb!;
}

function setMockDb(newDb: MockDb | undefined) {
    state.mockDb = newDb;
}

export function reloadDb() {
    console.debug("Reload mockDb");
    setMockDb(undefined);
    loadDb();
}

function loadFromData(data: MockDb | undefined) {
    const options = data?.options;
    if (data && data.VERSION !== defaultDb.VERSION) {
        console.warn("Resetting outdated mockDb from storage", data);
        data = undefined;
    } else {
        console.debug("Updated mockDb from storage", data);
    }

    const baseDb = defaultDb.buildMockDb(options);
    const newData =
        // If data has been not been modified, reload the defaults to ensure we have the latest version of them.
        data?.modified === false ? baseDb : { ...baseDb, ...data };

    setMockDb(newData);
}

//#region Storage

const mockDbKey = "mockDb";
const storage = jsonLocalStorage() as SyncStorage<MockDb | undefined>;

function loadDb() {
    if (!state.persistent) {
        console.warn("Loading default mock DB");
        setMockDb(defaultDb.buildMockDb());
        return;
    }

    storage.subscribe!(mockDbKey, loadFromData, undefined);
    loadFromData(storage.getItem(mockDbKey, undefined));
}

function saveDb() {
    if (!state.persistent) {
        console.error("Cannot save mockDb in non-persistent mode");
        return;
    }

    console.debug("Saving mockDb to localStorage");
    storage.setItem(mockDbKey, getMockDb());
}

function resetDb(newDb?: MockDb) {
    console.debug("Resetting mockDb");
    if (newDb) {
        setMockDb(newDb);
        saveDb();
    } else {
        state.mockDb = undefined;
        storage.removeItem(mockDbKey);
    }
}

export function resetDbWithOptions(options: MockDbOptions) {
    console.debug("Resetting mockDb with options", options);
    resetDb(defaultDb.buildMockDb(options));
}

//#endregion

//#region Hot reload

import.meta.hot?.accept(["./defaultDb.ts"], ([newDefaultDb]: unknown[]) => {
    defaultDb = newDefaultDb as typeof _defaultDb;

    if (state.mockDb && state.persistent) {
        console.debug("Hot reload defaultDb to %o", newDefaultDb);
        reloadDb();
    }

    document.dispatchEvent(new Event(event_mockDbHotReload));
});

//#endregion
