import type { StorageKey } from "@warrenio/api-spec/spec.oats.gen";

export const storageKeys: StorageKey[] = [
    {
        userId: "marko+6+test@oyenetwork.com",
        accessKey: "2JPM1ELQ6TBR09RJDQ9J",
        secretKey: "iyLpQxIDoEb55t99O4YTWusA5lXoFdJwNgGb1OA3",
    },
    {
        userId: "marko+6+test@oyenetwork.com",
        accessKey: "EGR1I8WG5F5Y7J20668X",
        secretKey: "BhtxTAZ4MtGBDjweQfS1bSrE77XLc3APWJr9jsXG",
    },
];
