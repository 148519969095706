import type { VmParameter } from "@warrenio/api-spec/spec.oats.gen";

export const vmParametersExample: VmParameter[] = [
    {
        parameter: "disks",
        description: "Size of main storage in gigabytes.",
        mandatory: true,
        type: "integer",
        constraint: "range",
        ignore_for: "disk_uuid",
        min: 20,
        max: 512,
        limited_by: "os_name",
        limits: [
            {
                os_name: "windows",
                min: 40,
            },
            {
                os_name: "wordpress",
                min: 5,
            },
            {
                os_name: "debian",
                min: 60,
            },
        ],
    },
    {
        parameter: "name",
        description:
            "Informative name, it will also be used to generate a suitable hostname for the VM (if applicable).",
        mandatory: true,
        type: "string",
    },
    {
        parameter: "os_name",
        description: "OS name of VM base image.",
        mandatory: true,
        ignore_for: "disk_uuid",
        type: "string",
        constraint: "enum",
        values: [
            "Rocky",
            "centos",
            "debian",
            "fedora",
            "service",
            "ubuntu",
            "windows",
            "django",
            "docker",
            "dsfghjfd",
            "ertetet",
            "grafana",
            "katsekatsekatse",
            "magento",
            "plesk",
            "wordpress",
        ],
    },
    {
        parameter: "os_version",
        description: "OS version of VM base image.",
        mandatory: true,
        ignore_for: "disk_uuid",
        type: "string",
        limited_by: "os_name",
        constraint: "enum",
        limits: [
            {
                os_name: "Rocky",
                values: ["9"],
            },
            {
                os_name: "centos",
                values: ["7.3.1611", "8.2", "7.6", "8.3.broken"],
            },
            {
                os_name: "debian",
                values: ["9.1", "9.8", "9.6.wildix", "10.0", "10.10"],
            },
            {
                os_name: "fedora",
                values: ["33", "36", "37"],
            },
            {
                os_name: "service",
                values: ["test-pg-13.4.r2", "test-pg-13.4.r3", "postgresql_13.4"],
            },
            {
                os_name: "ubuntu",
                values: ["16.04", "18.04", "20.04", "22.04_orig", "22.04"],
            },
            {
                os_name: "windows",
                values: ["2016", "2022", "2019", "2016R1"],
            },
            {
                os_name: "django",
                values: ["1"],
            },
            {
                os_name: "docker",
                values: ["20.10"],
            },
            {
                os_name: "dsfghjfd",
                values: ["1"],
            },
            {
                os_name: "ertetet",
                values: ["1"],
            },
            {
                os_name: "grafana",
                values: ["1"],
            },
            {
                os_name: "katsekatsekatse",
                values: ["1"],
            },
            {
                os_name: "magento",
                values: ["1"],
            },
            {
                os_name: "plesk",
                values: ["plesk"],
            },
            {
                os_name: "wordpress",
                values: ["5.5", "5.4", "5.9"],
            },
        ],
    },
    {
        parameter: "password",
        description:
            "Password for the user. Must contain at least one lowercase and one uppercase ASCII letter (a-z, A-Z) and at least one digit (0-9). Minimum length is 8 characters.",
        mandatory: true,
        type: "string",
        constraint: "regexp",
        expression: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$",
    },
    {
        parameter: "public_key",
        description:
            "Public key part of the SSH key pair for logging in to the VM. Must be in format 'ssh-keytype base64encodedkeykey...keykey OptionalComment'.",
        mandatory: false,
        type: "string",
        constraint: "regexp",
        expression: "^(ssh-(rsa|dsa|ecdsa|ed25519)|ecdsa-sha2-nistp[0-9]+) [A-Za-z0-9+/]+[=]{0,3}(\\s.*)?$",
    },
    {
        parameter: "ram",
        description: "Amount of RAM.",
        mandatory: true,
        type: "integer",
        constraint: "range",
        min: 1024,
        max: 65536,
        limited_by: "os_name",
        limits: [
            {
                os_name: "windows",
                min: 2048,
            },
        ],
    },
    {
        parameter: "username",
        description:
            "A user account will be created with this username, it can be used to log in to the VM. Can contain ASCII letters, digits, underscores and hyphens. Must start with a letter or underscore. Maximum length is 25 characters.",
        mandatory: true,
        type: "string",
        constraint: "regexp",
        expression: "^(?=.{1,25}$)[a-zA-Z_][0-9a-zA-Z_\\-]*$",
        limited_by: "os_name",
        limits: [
            {
                os_name: "windows",
                mandatory: false,
                description: "For Windows, username is always 'administrator'.",
            },
        ],
    },
    {
        parameter: "vcpu",
        description: "Number of CPUs.",
        mandatory: true,
        type: "integer",
        limited_by: "os_name",
        constraint: "range",
        min: 1,
        max: 16,
        limits: [
            {
                os_name: "windows",
                min: 2,
            },
        ],
    },
];
