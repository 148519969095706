import type { Storage } from "@warrenio/api-spec/spec.oats.gen";
import { defaultBillingAccountId } from "./billingAccountIds.ts";

const user_id = 29;

export const storageList: Storage[] = [
    {
        user_id,
        name: "Storage empty",
        size_bytes: 0,
        billing_account_id: defaultBillingAccountId,
        num_objects: 0,
        created_at: "2023-12-15T15:31:02.377+0000",
        modified_at: "2024-01-23T10:23:50.380+0000",
        owner: "kadri+shared-test+test@oyenetwork.com",
        is_suspended: false,
    },
    {
        user_id,
        name: "Suspended",
        size_bytes: 2842063,
        billing_account_id: defaultBillingAccountId,
        num_objects: 3,
        created_at: "2022-02-10T13:50:14.149+0000",
        modified_at: "2024-01-24T12:05:59.643+0000",
        owner: "kadri+shared-test+test@oyenetwork.com",
        is_suspended: true,
    },
    {
        user_id,
        name: "Storage with data",
        size_bytes: 63612865,
        billing_account_id: defaultBillingAccountId,
        num_objects: 18,
        created_at: "2022-03-04T09:58:29.294+0000",
        modified_at: "2024-01-26T15:26:16.426+0000",
        owner: "kadri+shared-test+test@oyenetwork.com",
        is_suspended: false,
    },
];
